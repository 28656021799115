import React,{useEffect, useState} from 'react';
import {Switch, Route, Redirect, BrowserRouter, NavLink } from 'react-router-dom';

import Home from './pages/Home/Home';
import Statistics from './pages/Statistics/Statistics';
import Classroom from './pages/Classroom/Classroom';
import Room from './pages/Room/Room';
import Child from './pages/Child/Child';
import AddMultipleAttendances from './pages/AddMultipleAttendances/AddMultipleAttendances';
import FoodMultipleAttendances from './pages/FoodMultipleAttendances/FoodMultipleAttendances';
import SearchedChildren from './pages/SearchedChildren/SearchedChildren';
import AddNewChild from './pages/AddNewChild/AddNewChild';
import ChildRegistration from './pages/ChildRegistration/ChildRegistration';
import ChildRegistrationVoucher from './pages/ChildRegistrationVoucher/ChildRegistrationVoucher';
import StartChildRegistration from './pages/StartChildRegistration/StartChildRegistration';
import Table from './pages/Table/Table';
import ExtractData from './pages/ExtractData/ExtractData';
import UsersList from './pages/UsersList/UsersList';
import Receipts from './pages/Receipts/Receipts';
import PaymentData from './pages/PaymentData/PaymentData';
import PaymentSituation from './pages/PaymentSituation/PaymentSituation';
import MonthlyPayment from './pages/MonthlyPayment/MonthlyPayment';
import WeeksList from './pages/WeeksList/WeeksList';
import ActivityList from './pages/ActivityList/ActivityList';
import Vouchers from './pages/Vouchers/Vouchers';
import Start from './pages/Start/Start';
import Auth from './pages/Auth/Auth';
import {AuthContext} from './shared/util/auth-context';
import {useAuth} from './shared/hooks/auth-hook';

import LoadingSpinner from './shared/components/LoadingSpinner/LoadingSpinner';
import './App.css';
import logo from './vi_logo.png'


const App=()=> {
  let routes = (
    <Switch>
       <Route path="/registration" exact>
          <ChildRegistration/>
        </Route>
        <Route path="/voucher/:voucherId" exact>
          <ChildRegistrationVoucher/>
        </Route>
        <Route path="/" exact>
          <StartChildRegistration/>
        </Route>
        <Redirect to="/"/>
    </Switch>
  )
  return (
    <BrowserRouter>
      <div className="App">
        <NavLink className="App-header" to="/">
          <img src={logo} height="60" width="60" alt="vi_logo" />
          <h1>Vara Împreună 2022</h1>  
        </NavLink>
        {routes}
      </div>
    </BrowserRouter>
  );
}

export default App;
