const VALIDATOR_TYPE_REQUIRE = "REQUIRE";
const VALIDATOR_TYPE_MINLENGTH = "MINLENGTH";
const VALIDATOR_TYPE_MAXLENGTH = "MAXLENGTH";
const VALIDATOR_TYPE_MIN = "MIN";
const VALIDATOR_TYPE_MAX = "MAX";
const VALIDATOR_TYPE_NOT_EQUAL = "NOT_EQUAL";
const VALIDATOR_TYPE_EMAIL = "EMAIL";
const VALIDATOR_TYPE_FILE = "FILE";

export const VALIDATOR_REQUIRE = () => ({type: VALIDATOR_TYPE_REQUIRE})
export const VALIDATOR_FILE = () => ({type: VALIDATOR_TYPE_FILE})
export const VALIDATOR_MAXLENGTH = (val) => ({type: VALIDATOR_TYPE_MAXLENGTH, val: val})
export const VALIDATOR_MINLENGTH = (val) => ({type: VALIDATOR_TYPE_MINLENGTH, val: val})
export const VALIDATOR_MIN = (val) => ({type: VALIDATOR_TYPE_MIN, val: val})
export const VALIDATOR_MAX = (val) => ({type: VALIDATOR_TYPE_MAX, val: val})
export const VALIDATOR_NOT_EQUAL = (val) => ({type: VALIDATOR_TYPE_NOT_EQUAL, val: val})
export const VALIDATOR_EMAIL = () => ({type: VALIDATOR_TYPE_EMAIL})

export const validate = (value, validators) =>{
    let isValid = true;
    // console.log(validators);
    for(const validator of validators){
        // console.log("Validare REQUIRED", value.trim().length);
        // console.log('validator.type', validator().type);
        if(validator.type === VALIDATOR_TYPE_REQUIRE){
            isValid = isValid && value.trim().length > 0;
        }
        if(validator.type === VALIDATOR_TYPE_MINLENGTH){
            isValid = isValid && value.trim().length >= validator.val;
        }
        if(validator.type === VALIDATOR_TYPE_MAXLENGTH){
            isValid = isValid && value.trim().length <= validator.val;
        }
        if(validator.type === VALIDATOR_TYPE_MIN){
            isValid = isValid && +value >= validator.val;
        }
        if(validator.type === VALIDATOR_TYPE_MAX){
            isValid = isValid && +value <= validator.val;
        }
        if(validator.type === VALIDATOR_TYPE_NOT_EQUAL){
            isValid = isValid && value != validator.val;
        }
        if(validator.type === VALIDATOR_TYPE_EMAIL){
            isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
        }
    }

    return isValid;
}