import {useState, useCallback, useRef, useEffect, useContext} from 'react';
import {AuthContext} from '../util/auth-context'

export const useHttpClient = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const auth = useContext(AuthContext);
    const activeHttpRequests = useRef([]);

    const sendRequest = useCallback(
        async(url, method = "GET", body = null, headers = {}) =>{
            setIsLoading(true);
            const httpAbortCtrl = new AbortController();
            activeHttpRequests.current.push(httpAbortCtrl);

            try {

                const response = await fetch(url, {
                    method,
                    body,
                    headers,
                    signal: httpAbortCtrl.signal
                });
                let responseData;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1){
                    responseData = await response.json();
                }
                else{
                    responseData = response;
                }

                activeHttpRequests.current = activeHttpRequests.current.filter(
                    reqCtrl => reqCtrl !== httpAbortCtrl
                );

                if(!response.ok){
                    // console.log('status response', response.status);
                    if(response.status === 401){
                        //auth.logout()
                    }
                    throw new Error(responseData.message);
                }else{
                    // console.log('status response', response.status);
                }

                setIsLoading(false);
                return responseData;
            } catch (err) {
                if(err.message === 'Failed to fetch'){
                    setError("Connexion failed! :/");
                }
                else{
                    setError(err.message);
                }
                setIsLoading(false);
                //auth.logout();
                throw err;
            }

        },
        // eslint-disable-next-line
        []
    );

    const clearError = () =>{
        setError(null);
    }

    const raiseError = (message)=>{
        setError(message)
    }

    useEffect(()=>{
        return ()=>{
            activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
        }
    }, []);

    return {isLoading, error, sendRequest, clearError, raiseError};

}