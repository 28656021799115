import React, {useContext, useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LoadingSpinner from '../../shared/components/LoadingSpinner/LoadingSpinner';

import Input from '../../shared/components/Input/Input';
import Button from '../../shared/components/Button/Button';
import ErrorModal from '../../shared/components/ErrorModal/ErrorModal';
import Square from '../../shared/components/Square/Square';
import {VALIDATOR_MIN, VALIDATOR_REQUIRE, VALIDATOR_NOT_EQUAL, VALIDATOR_EMAIL} from '../../shared/util/validators'
import {useHttpClient} from '../../shared/hooks/http-hook';
import {useForm} from '../../shared/hooks/form-hook'
import './ChildRegistrationVoucher.css';

import checkedLogo from '../../Checkmark.png'
import warningLogo from '../../warning.png'

const ChildRegistration =() =>{
    const {isLoading, error, sendRequest, clearError, raiseError} = useHttpClient();
    const history = useHistory();
    const [limits, setLimits] = useState();
    const [teamLimits, setTeamLimits] = useState();
    const [activities, setActivities] = useState();
    const [phase, setPhase] = useState(0);
    const [wasRegistred, setWasRegistred] = useState(false);
    const [payment, setPayment] = useState();
    const [message, setMessage] = useState();
    const [voucher, setVoucher] = useState();
    const {voucherId} = useParams();


    const [formState, inputHandler, setFormData] = useForm(
        {
            packNum:{
                value: '',
                isValid: false
            },
            classroom:{
                value: '',
                isValid: false
            },
            name: {
                value: '',
                isValid: false
            },
            birthDate: {
                value: '',
                isValid: false
            },
            school: {
                value: '',
                isValid: false
            },
            gender: {
                value: '',
                isValid: false
            },
            mother: {
                value: '',
                isValid: false
            },
            mother_phone: {
                value: '',
                isValid: false
            },
            mother_email: {
                value: '',
                isValid: false
            },
            father: {
                value: '',
                isValid: false
            },
            father_phone: {
                value: '',
                isValid: false
            },
            father_email: {
                value: '',
                isValid: false
            },
            guardianship_name:{
                value: '',
                isValid: false
            },
            guardianship_phone:{
                value: '',
                isValid: false
            },
            str: {
                value: '',
                isValid: false
            },
            nr: {
                value: '',
                isValid: false
            },
            bl: {
                value: '',
                isValid: false
            },
            sc: {
                value: '',
                isValid: false
            },
            ap: {
                value: '',
                isValid: false
            },
            localitate: {
                value: '',
                isValid: false
            },
            judet: {
                value: '',
                isValid: false
            },
            team: {
                value: '',
                isValid: false
            },
            food:{
                value: '',
                isValid: false
            },
            sibling:{
                value: '',
                isValid: false
            }, 
            activity1:{
                value: '',
                isValid: false
            }, 
            activity2:{
                value: '',
                isValid: false
            }, 
            old:{
                value: false,
                isValid: true
            },        
            firstDay:{ 
                value: true ? '13-6-2022': //DE MODIFICAT
                    `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getUTCFullYear()}`,
                isValid: true
            }
        },
        false
    );
    useEffect(()=>{
        if(voucherId == undefined || voucherId == null){
            history.goBack();
        }
        const getPacks = async()=>{
            try {
                const response = await sendRequest(
                    // `${process.env.REACT_APP_BACKEND_REQ_URL}/get-classroom-limit/${formState.inputs.packNum.value}`,
                    `${process.env.REACT_APP_BACKEND_REQ_URL}/get-packs`,
                    'GET',
                    null,
                    {
                    }
                );
                //console.log("limits: ", response);
                setLimits(response);
            } catch (error) {
                //console.log(error)
            }
        }
        const getVoucher = async()=>{
            try {
                const response = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_REQ_URL}/get-voucher/${voucherId}`,
                    'GET',
                    null,
                    {
                    }
                );
                //console.log("voucher: ", response);
                setVoucher(response);
            } catch (error) {
                //console.log(error)
            }
        }
        getPacks();
        getVoucher();
        //console.log("auth.context", auth);
    }, [setLimits])

    useEffect(()=>{
        //console.log("formState", formState);
    }, [formState])
    useEffect(()=>{
        //console.log("phase", phase);
    }, [phase])


    const setPackNum = (index)=>{
        //console.log("select vara: ", index);
        if(index >= 1 && index <= limits.packs.length){
            formState.inputs.packNum.value = +index;
            formState.inputs.packNum.isValid = true;
            let finalVal = true;
            //console.log("entrie", Object.entries(formState.inputs))
            for(const [key, input] of  Object.entries(formState.inputs)){
                if(input.isValid == false){
                    finalVal = false;
                    break;
                }
            }
        setFormData(formState.inputs, finalVal);
        }
    }

    const changeFood = (value) =>{
        formState.inputs.food.value = value;
        formState.inputs.food.isValid = true;
        let finalVal = true;
        for(const [key, input] of  Object.entries(formState.inputs)){
            if(input.isValid == false){
                finalVal = false;
                break;
            }
        }
        setFormData(formState.inputs, finalVal);
    }

    const changeSibling = (value) =>{
        formState.inputs.sibling.value = value;
        formState.inputs.sibling.isValid = true;
        let finalVal = true;
        for(const [key, input] of  Object.entries(formState.inputs)){
            if(input.isValid == false){
                finalVal = false;
                break;
            }
        }
        setFormData(formState.inputs, finalVal);
    }

    const selectTeam = (color) =>{
        formState.inputs.team.value = color;
        formState.inputs.team.isValid = true;
        let finalVal = true;
        for(const [key, input] of  Object.entries(formState.inputs)){
            if(input.isValid == false){
                finalVal = false;
                break;
            }
        }
        setFormData(formState.inputs, finalVal);
    }

    const goPhase1 = event=>{
        event.preventDefault(); 
        console.log("set pack 1");
        if(voucher.pack != 0){
            console.log("intra in if");
            setPackNum(voucher.pack);
        }
        else{
            console.log("NU intra in if");
        }
        window.scrollTo(0, 0);
        setPhase(1)
    }

    const goPhase2 = event=>{
        event.preventDefault(); 
        
        window.scrollTo(0, 0);
        setPhase(2)
    }

    const goInterPhase23 = async event => {
        event.preventDefault(); 
        window.scrollTo(0, 0); 
        setPhase(23)
    }

    const goPhase3 = async event => {
        event.preventDefault(); 
        //console.log("hai la faza 3");
        window.scrollTo(0, 0); 
        setPhase(3)

        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_REQ_URL}/get-team-limits/${formState.inputs.packNum.value}/${voucherId}`,
                'GET',
                null,
                {
                }
            );
            setTeamLimits((response.limits))
            window.scrollTo(0, 0); 
            setPhase(3)
        } catch (error) {
            //console.log(error)
            window.scrollTo(0, 0); 
            setPhase(2)
        }
    }
    const goPhase4 = async event => {
        event.preventDefault(); 
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_REQ_URL}/get-activities/${formState.inputs.classroom.value}/${formState.inputs.packNum.value}/${voucher._id}`,
                'GET',
                null,
                {
                }
            );
            //console.log("activities: ", response);
            setActivities(response);
        } catch (error) {
            //console.log(error)
        }
        window.scrollTo(0, 0); 
        setPhase(4)
    }
    const goPhase5 = event => {
        event.preventDefault(); 
        window.scrollTo(0, 0); 
        setPhase(5)
    }
    const goPhase6 = event => {
        event.preventDefault(); 
        window.scrollTo(0, 0); 
        setPhase(6)
    }
    const goPhase7 = event => {
        event.preventDefault(); 
        window.scrollTo(0, 0); 
        setPhase(7)
    }

    const initRegisterAgain = async event =>{
        event.preventDefault(); 
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_REQ_URL}/check-registred-child/
                    ${formState.inputs.name.value}/
                    ${formState.inputs.classroom.value}/
                    ${formState.inputs.birthDate.value}/
                    ${formState.inputs.packNum.value}`,
                'GET',
                null,
                {
                }
            );
            //console.log("response: ", response);
            if(response.message == "OK"){
                window.scrollTo(0, 0); 
                setWasRegistred(true);
                setPhase(3)
            }
        } catch (error) {
            //console.log(error)
        }
    }

    const registerChild = async(event) =>{
        event.preventDefault();
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_REQ_URL}/register-child`,
                'POST',
                JSON.stringify({ 
                    name: formState.inputs.name.value,
                    birthDate: formState.inputs.birthDate.value,
                    school:formState.inputs.school.value,
                    gender:formState.inputs.gender.value,
                    classroom: formState.inputs.classroom.value,
                    firstDay: formState.inputs.firstDay.value,
                    parents:{
                        mother: {
                            name: formState.inputs.mother.value,
                            phone: formState.inputs.mother_phone.value,
                            email: formState.inputs.mother_email.value
                        },
                        father: {
                            name: formState.inputs.father.value,
                            phone: formState.inputs.father_phone.value,
                            email: formState.inputs.father_email.value
                        }
                    },
                    guardianship:{
                        name:  formState.inputs.guardianship_name.value,
                        phone:  formState.inputs.guardianship_phone.value
                    },
                    address:{
                        str: formState.inputs.str.value,
                        nr: formState.inputs.nr.value,
                        bl: formState.inputs.bl.value,
                        sc: formState.inputs.sc.value,
                        ap: formState.inputs.ap.value,
                        localitate: formState.inputs.localitate.value,
                        judet: formState.inputs.judet.value
                    },
                    team: formState.inputs.team.value,
                    packNum: formState.inputs.packNum.value,
                    activity1: formState.inputs.activity1.value,
                    activity2: formState.inputs.activity2.value,
                    food: formState.inputs.food.value,
                    // age: formState.inputs.age.value,
                    sibling: formState.inputs.sibling.value,
                    old: formState.inputs.old.value,
                    entryDate: new Date()
                }),
                {
                    'Content-Type': 'application/json',
                }
            );
            if(response.status == "OK"){
                window.scrollTo(0, 0); 
                setPhase(8)
            }
            setPayment(response.toPay);
            console.log("response", response);
        } catch (error) {
            //console.log(error)
        }
    }

    const registerChildAgain = async(event) =>{
        event.preventDefault();
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_REQ_URL}/register-child-again`,
                'POST',
                JSON.stringify({ 
                    name: formState.inputs.name.value,
                    birthDate: formState.inputs.birthDate.value,
                    classroom: formState.inputs.classroom.value,
                    team: formState.inputs.team.value,
                    packNum: formState.inputs.packNum.value,
                    activity1: formState.inputs.activity1.value,
                    activity2: formState.inputs.activity2.value,
                    food: formState.inputs.food.value,
                    // age: formState.inputs.age.value,
                    sibling: formState.inputs.sibling.value,
                    old: formState.inputs.old.value,
                    entryDate: new Date()
                }),
                {
                    'Content-Type': 'application/json'
                }
            );
            if(response.message == "Child updated successfully!"){
                window.scrollTo(0, 0); 
                setPhase(4)
            }
            //console.log(response)
        } catch (error) {
            //console.log(error)
        }
    }

    const phase0 = (
        <div className="child-registration-container">
            <form  style={{width: '80%', margin: 'auto'}}>
                <br/>
                <h1>Bine ați venit la Vara Împreună 2022!</h1>
                <h3>IMPORTANT! Înainte de a începe înscrierea vă invităm să citiți informațiile generale legate de Vara Împreună <a href="https://donboscobc.ro/vara-impreuna-2022-informatii-utile/" style={{color: 'blue'}} target="_blank">pe site-ul fundației.</a></h3>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Acesta este un formular de înscriere și trebuie validat la sediul fundației, altfel înscrierea nu va fi luată în considerare.</h4>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Înscrierea se face in limita locurilor disponibile. Dacă a mai rămas un singur loc disponibil, acesta va fi ocupat de persoana care termină prima de completat formularul.</h4>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Introducerea unor date false poate duce la anularea înscrierii!</h4>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Înscrierea este considerată finalizată abia după apasarea butonului "FINALIZARE" și la primirea mesajului de confirmare!</h4>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Înscrierea permite selectarea unei singure "Veri Impreună". Dacă se dorește înscrierea pentru mai multe perioade, atunci se va completa din nou formularul!</h4>
                <h4 style={{margin: "10px", padding: "0px", textAlign: "left"}}>Toate câmpurile sunt obligatorii! Completați cu <span style={{backgroundColor: 'lightgray'}}>&nbsp; - &nbsp;</span> acolo nu aveti date (de exemplu pentru 'tutore legal' daca nu este cazul sau pentru adresă).</h4>
                <br/>
                {voucher && <Button 
                    onClick={goPhase1}
                    mode="next"
                    // link='/child-registration'
                >Începe înscrierea </Button>}
            </form>
            <br/>
        </div>
    );

    const phase1 = (
        <>
        <div className="child-registration-container">
            <form style={{width: '80%', margin: 'auto'}}>
                <br/>
                <h1>Alege perioada</h1>
                {voucher && voucher.pack !== 0 && <h4>*Perioada nu poate fi modificată. Aceasta a fost introdusă în momentul generarii voucher-ului.</h4>}
                <br/>
                {voucher && <div className="square-container">
                    {limits && limits.packs.map((pack, i) => (
                        <Square 
                            key = {`vara${i+1}`}
                            type = {'selection'}
                            name= {`Vara Împreună ${i+1}`}
                            onClick={voucher.pack === 0 ? 
                                    ()=>setPackNum(i+1) : ()=>{} }
                            selected = {formState.inputs.packNum.value == (i+1) ? true: false}
                            >
                                <p>{pack.dates[0].first.day}.{pack.dates[0].first.month} -&nbsp;
                                    {pack.dates[pack.dates.length-1].last.day}.{pack.dates[pack.dates.length-1].last.month}</p>
                        </Square>
                    ))}
                </div>}

                <Button 
                    type="button" 
                    mode="back"
                    onClick={()=>setPhase(0)}>
                Informatii</Button>
                <Button 
                    disabled={ !formState.inputs.packNum.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase2}>
                Continuă</Button>
            </form>
            <br/>
            <p>1 / 8</p>
            <br/>

        </div>
        </>
    );
    
    const phase2 = (
        <>
        {limits && limits.packs && formState && formState.inputs && 
            formState.inputs.packNum.value && 
            <div className="child-registration-container">
            <br/>
            <h1>Datele copilului</h1>
            <br/>
            <h4>*Datele nu pot fi modificate. Acestea au fost introduse în momentul generării voucher-ului.</h4>
            <form style={{width: '80%', margin: 'auto'}}>
                <Input
                    initialValue={voucher.name}
                    initialIsValid={true}
                    readonly = 'readonly'
                   id="name"
                    type="text"
                    label="Nume complet"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numele complet!"
                />
                <Input
                    initialValue={voucher.birthDate}
                    initialIsValid={true}
                    readonly = 'readonly'
                    id="birthDate"
                    type="date"
                    placeholder="dd-mm-yyyy"
                    min="1997-01-01" 
                    max="2030-12-31"
                    label="Data nasterii"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = 
                        "Adaugati data nasterii!"
                />
                <Input 
                    initialValue={voucher.classroom}
                    initialIsValid={true}
                    readonly = 'readonly'
                    id="classroom"
                    type="number"
                    label="Clasa din anul scolar in curs (2021-2022)"
                    onInput={inputHandler}
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MIN(0)]}
                    errorText="Introduceti clasa din anul scolar in curs (2021-2022)!"
                />
                <Input
                    initialValue={voucher.gender}
                    initialIsValid={true}
                    disabled = {true}
                    id="gender"
                    type="select"
                    placeholder="Gen"
                    pickOptionText = "Selectati genul"
                    onInput={inputHandler}
                    options={['baiat', 'fata']}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Selectati genul copilului!"
                />
                <Button 
                    type="button"
                    mode="back"
                    onClick={event => {event.preventDefault(); setPhase(1)}}>
                Înapoi</Button>
                <Button 
                    disabled={ !formState.inputs.name.isValid || 
                                !formState.inputs.birthDate.isValid || 
                                !formState.inputs.classroom.isValid || 
                                !formState.inputs.gender.isValid || 
                                !formState.inputs.packNum.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goInterPhase23}>
                Continuă</Button>
            </form>
            <br/>
            <p>2 / 8</p>
            <br/>

        </div>}
        </>
    );

    const interPhase23 = (
        <>
        {limits && limits.packs && formState && formState.inputs && 
            formState.inputs.packNum.value && 
            <div className="child-registration-container">
            <br/>
            <h1>Datele părinților</h1>
            <br/>
            <form style={{width: '80%', margin: 'auto'}}>
            <h4>*Toate câmpurile sunt obligatorii. Completați cu <span style={{backgroundColor: 'lightgray'}}>&nbsp; - &nbsp;</span> acolo nu aveti date (de exemplu pentru 'tutore legal' daca nu este cazul).</h4>
                <Input
                    id="mother"
                    initialValue={formState.inputs.mother.value}
                    initialIsValid={formState.inputs.mother.isValid}
                    type="text"
                    label="Numele mamei"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numele mamei!"
                />
                <Input
                    id="mother_phone"
                    initialValue={formState.inputs.mother_phone.value}
                    initialIsValid={formState.inputs.mother_phone.isValid}
                    type="text"
                    label="Numarul de telefon al mamei"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numarul de telefon al mamei!"
                />
                <Input
                    id="father"
                    initialValue={formState.inputs.father.value}
                    initialIsValid={formState.inputs.father.isValid}
                    type="text"
                    label="Numele tatalui"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numele tatalui!"
                />
                <Input
                    id="father_phone"
                    initialValue={formState.inputs.father_phone.value}
                    initialIsValid={formState.inputs.father_phone.isValid}
                    type="text"
                    label="Numarul de telefon al tatalui"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numarul de telefon al tatalui!"
                />
                <Input
                    id="guardianship_name"
                    initialValue={formState.inputs.guardianship_name.value}
                    initialIsValid={formState.inputs.guardianship_name.isValid}
                    type="text"
                    label="Nume tutore legal"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numele tutorelui legal (daca este cazul)!"
                />
                <Input
                    id="guardianship_phone"
                    initialValue={formState.inputs.guardianship_phone.value}
                    initialIsValid={formState.inputs.guardianship_phone.isValid}
                    type="text"
                    label="Nr. telefon tutore legal"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati nr. de tel. al tutorelui legal (daca este cazul)!"
                />
                <Button 
                    type="button"
                    mode="back"
                    onClick={goPhase2}>
                Înapoi</Button>
                <Button 
                    disabled={ !formState.inputs.mother.isValid || 
                                !formState.inputs.mother_phone.isValid || 
                                !formState.inputs.father.isValid || 
                                !formState.inputs.father_phone.isValid || 
                                !formState.inputs.guardianship_name.isValid || 
                                !formState.inputs.guardianship_phone.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase3}>
                Continuă</Button>
            </form>
            <br/>
            <p>3 / 8</p>
            <br/>

        </div>}
        </>
    );

    const phase3 = (
        <>
        {teamLimits && formState &&
            formState.inputs.packNum.value  &&
            <div className="child-registration-container">
            <br/>

            {/* CULOARE ECHIPA */}
            <h1>Alege culoarea echipei</h1>
            <br/>
            <form style={{width: '80%', margin: 'auto'}}>

                <div className="square-container">
                    <Square 
                        key = {`redTeam`}
                        type = {'team'}
                        color = 'red' 
                        // available = {teamLimits.red}
                        available = {voucher.type === 'normal' ? teamLimits.red 
                                    : 10}
                        name= {`Echipa rosie`}
                        onClick={()=>selectTeam('red')}
                        selected = {formState.inputs.team.value == 'red'?
                                    formState.inputs.team.value : ''}
                        >
                            <h2>Echipa roșie</h2>
                    </Square>
                    <Square 
                        key = {`greenTeam`}
                        type = {'team'}
                        color = 'green' 
                        // available = {teamLimits.green}
                        available = {voucher.type === 'normal' ? teamLimits.green 
                                    : 10}
                        name= {`Echipa verde`}
                        onClick={()=>selectTeam('green')}
                        selected = {formState.inputs.team.value == 'green'?
                        formState.inputs.team.value : ''}
                        >
                            <h2>Echipa verde</h2>
                    </Square>
                    </div>
                    <div className="square-container">
                    <Square 
                        key = {`blueTeam`}
                        type = {'team'}
                        color = 'blue' 
                        // available = {teamLimits.blue}
                        available = {voucher.type === 'normal' ? teamLimits.blue 
                                    : 10}
                        name= {`Echipa albastra`}
                        onClick={()=>selectTeam('blue')}
                        selected = {formState.inputs.team.value == 'blue'?
                                    formState.inputs.team.value : ''}
                        >
                            <h2>Echipa albastra</h2>
                    </Square>
                    <Square 
                        key = {`yellowTeam`}
                        type = {'team'}
                        color = 'yellow' 
                        available = {voucher.type === 'normal' ? teamLimits.yellow 
                                    : 10}
                        name= {`Echipa galbena`}
                        onClick={()=>selectTeam('yellow')}
                        selected = {formState.inputs.team.value == 'yellow'?
                                    formState.inputs.team.value : ''}
                        >
                            <h2>Echipa galbena</h2>
                    </Square>
                </div>
                <Button 
                    type="button"
                    mode="back"
                    onClick={goInterPhase23}>
                Înapoi</Button>
                <Button 
                    disabled={  !formState.inputs.name.isValid || 
                                !formState.inputs.birthDate.isValid || 
                                !formState.inputs.classroom.isValid || 
                                !formState.inputs.gender.isValid || 
                                !formState.inputs.packNum.isValid ||
                                !formState.inputs.team.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase4}>
                Continuă</Button>
            </form>
            <br/>
            <p>4 / 8</p>
            <br/>

        </div>}
        </>
    );

    const phase4 = (
        <div className="child-registration-container">
            <br/>
            <h1>Activități</h1>
            <form style={{width: '90%', margin: 'auto'}}>   
                <br/>
                {/* <h4 style={{margin: "0px"}}>Alegeti doua activitati</h4> */}
                <h5 style={{margin: "0px"}}>Atenție! Activitățile au un numar limitat de copii, așadar apar în listă doar cele care au locuri disponibile.</h5>
                <h4>Pentru o scurtă descriere a activităților accesați <a style={{color: 'blue'}} href="https://donboscobc.ro/vara-impreuna-2022-informatii-utile/" target="_blank">această pagină</a> </h4>
                {activities && <Input
                    // initialValue={formState.inputs.activity1.value}
                    // initialIsValid={formState.inputs.activity1.isValid}
                    id="activity1"
                    type="select"
                    placeholder="Activitatea nr. 1"
                    pickOptionText = "Alege activitatea numarul 1"
                    onInput={inputHandler}
                    options={[...activities.activities1.map(activity=> activity.name)]}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Alegeti activitatea #1!"
                />}

                {activities && <Input
                    // initialValue={formState.inputs.activity2.value}
                    // initialIsValid={formState.inputs.activity2.isValid}
                    id="activity2"
                    type="select"
                    placeholder="Activitatea nr. 2"
                    pickOptionText = "Alege activitatea numarul 2"
                    onInput={inputHandler}
                    options={[...activities.activities2.map(activity=> activity.name)]}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Alegeti activitatea #2! Aceasta trebuie sa fie diferita de Activitatea #1"
                />}
                <Button 
                    type="button"
                    mode="back"
                    onClick={goPhase3}>
                Înapoi</Button>
                <Button 
                    disabled={  !formState.inputs.name.isValid || 
                                !formState.inputs.birthDate.isValid || 
                                !formState.inputs.classroom.isValid || 
                                !formState.inputs.gender.isValid || 
                                !formState.inputs.packNum.isValid ||
                                !formState.inputs.team.isValid ||
                                !formState.inputs.activity1.isValid ||
                                !formState.inputs.activity2.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase5}>
                Continuă</Button>
            </form>
            <br/>
            <p>5 / 8</p>
            <br/>

        </div>
    );

    const phase5 = (
        <div className="child-registration-container">
            <br/>
            <form style={{width: '90%', margin: 'auto'}}>   
            <h1>Opțiuni prânz</h1>
                    <br/>
                    {/* MENIU / FOOD */}
                    <div 
                        onClick = {()=>changeFood(false)}
                        // style={{"display":"grid"}}
                        className={ `child-registration-square ${
                            formState.inputs.food.value === false
                            &&'child-registration-square-green'}`}
                    >Pachet propriu</div>    
                    <div 
                        onClick = {()=>changeFood(true)}
                        // style={{"display":"grid"}}
                        className={ `child-registration-square ${
                            formState.inputs.food.value === true
                            &&'child-registration-square-green'}`}
                            >Meniu catering</div>    
                    <br/>
                    <h5 style={{margin: "0px", color: 'red'}}>*Meniul presupune un cost suplimentar de <i>14 lei pe zi</i></h5>
                    <br/>
                <Button 
                    type="button"
                    mode="back"
                    onClick={goPhase4}>
                Înapoi</Button>
                <Button 
                    disabled={  !formState.inputs.name.isValid || 
                                !formState.inputs.birthDate.isValid || 
                                !formState.inputs.classroom.isValid || 
                                !formState.inputs.gender.isValid || 
                                !formState.inputs.packNum.isValid ||
                                !formState.inputs.team.isValid ||
                                !formState.inputs.food.isValid ||
                                !formState.inputs.activity1.isValid ||
                                !formState.inputs.activity2.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase6}>
                Continuă</Button>
            </form>
            <br/>
            <p>6 / 8</p>
            <br/>
        </div>
    );

    const phase6 = (
        <div className="child-registration-container">
            <br/>
            <form style={{width: '90%', margin: 'auto'}}>    
           {/* FRATE SAU SORA INSCRIS(A) / DISCOUNT */}
           <h1>Frate sau sora înscris(ă)</h1>
                    <br/>
                    <div 
                        onClick = {()=>changeSibling(false)}
                        // style={{"display":"grid"}}
                        className={ `child-registration-square ${
                            formState.inputs.sibling.value === false
                            &&'child-registration-square-green'}`}
                    >NU</div>    
                    <div 
                        onClick = {()=>changeSibling(true)}
                        // style={{"display":"grid"}}
                        className={ `child-registration-square ${
                            formState.inputs.sibling.value === true
                            &&'child-registration-square-green'}`}
                            >DA</div>    
                    <br/>
                    <h5 style={{margin: "0px", color: 'red'}}>*Frații beneficiază de <i>10% reducere</i> pentru  înscrierea la aceeași "Vară Împreună"</h5>
                    <br/>
                <Button 
                    type="button"
                    mode="back"
                    onClick={goPhase5}>
                Înapoi</Button>
                <Button 
                    disabled={  !formState.inputs.name.isValid || 
                                !formState.inputs.birthDate.isValid || 
                                !formState.inputs.classroom.isValid || 
                                !formState.inputs.gender.isValid || 
                                !formState.inputs.packNum.isValid ||
                                !formState.inputs.team.isValid ||
                                !formState.inputs.sibling.isValid ||
                                !formState.inputs.food.isValid ||
                                !formState.inputs.activity1.isValid ||
                                !formState.inputs.activity2.isValid}
                    type="submit" 
                    mode="next"
                    onClick={goPhase7}>
                Continuă</Button>
            </form>
            <br/>
            <p>7 / 8</p>
            <br/>
        </div>
    );

    const phase7 = (
        <>
        {formState && <div className="child-registration-container">
         
            <br/>
            <h1>Completați formularul</h1>
            <br/>
            <form style={{width: '70%', margin: 'auto'}}>
            <h4>*Toate câmpurile sunt obligatorii. Completați cu <span style={{backgroundColor: 'lightgray'}}>&nbsp; - &nbsp;</span> acolo nu aveti date (de exemplu pentru 'bloc/scară/apartament' daca nu este cazul).</h4>
                <Input
                    id="school"
                    initialValue={formState.inputs.school.value}
                    initialIsValid={formState.inputs.school.isValid}
                    type="text"
                    label="Scoala"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati scoala la care este inscris copilul!"
                />
                <Input
                    id="mother_email"
                    initialValue={formState.inputs.mother_email.value}
                    initialIsValid={formState.inputs.mother_email.isValid}
                    type="text"
                    label="Email-ul mamei"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati email-ul mamei!"
                />
                <Input
                    id="father_email"
                    initialValue={formState.inputs.father_email.value}
                    initialIsValid={formState.inputs.father_email.isValid}
                    type="text"
                    label="Email-ul tatalui"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati email-ul tatalui!"
                />
                <Input
                    id="str"
                    initialValue={formState.inputs.str.value}
                    initialIsValid={formState.inputs.str.isValid}
                    type="text"
                    label="Strada"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numele strazii!"
                />
                <Input
                    id="nr"
                    initialValue={formState.inputs.nr.value}
                    initialIsValid={formState.inputs.nr.isValid}
                    type="text"
                    label="Numarul"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati numarul locuintei!"
                />
                <Input
                    id="bl"
                    initialValue={formState.inputs.bl.value}
                    initialIsValid={formState.inputs.bl.isValid}
                    type="text"
                    label="Bloc"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati blocul!"
                />
                <Input
                    id="sc"
                    initialValue={formState.inputs.sc.value}
                    initialIsValid={formState.inputs.sc.isValid}
                    type="text"
                    label="Scara"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati scara!"
                />

                <Input
                    id="ap"
                    initialValue={formState.inputs.ap.value}
                    initialIsValid={formState.inputs.ap.isValid}
                    type="text"
                    label="Apartamentul"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati apartamentul!"
                />
                <Input
                    id="localitate"
                    initialValue={formState.inputs.localitate.value}
                    initialIsValid={formState.inputs.localitate.isValid}
                    type="text"
                    label="Localitatea"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati localitatea!"
                />
                <Input
                    id="judet"
                    initialValue={formState.inputs.judet.value}
                    initialIsValid={formState.inputs.judet.isValid}
                    type="text"
                    label="Judet"
                    onInput={inputHandler}
                    validators = {[VALIDATOR_REQUIRE()]}
                    errorText = "Adaugati judetul!"
                />

                <div>
                <Button 
                    type="button"
                    mode="back"
                    onClick={goPhase6}>
                Înapoi</Button>
                <Button 
                    disabled = {!formState.isValid}
                    // disabled = {!formState.isValid || formState.inputs.activity1.value == formState.inputs.activity2.value }
                    type="submit" 
                    mode="next"
                    onClick={registerChild}>
                FINALIZARE</Button>
                </div>
            </form>
            <br/>
            <p>8 / 8</p>
            <br/>
        </div>}
        </>
    );

    const endMessage = (
        <div className="child-registration-container">
            <br/>
            <img src={checkedLogo} style={{margin: 'auto'}} height="80" width="80" alt="check mark" />
            <h1 style={{color: '#008f51'}}>Locul a fost rezervat</h1>
            <p>Pentru confirmarea locului, verificarea datelor si plata veti fi contactact de secretariatul fundatiei.</p>
            {payment && <p>Costul total pentru perioadele si optiunile selectate:&nbsp;
                 {payment.tax + payment.food} lei! Taxa de {payment.tax} lei 
                 trebuie achitata in momentul semnarii contractului si&nbsp;
                 {payment.food} lei pentru catering pana joi, inaintea inceperii
                 activitatilor.</p>}
            <h3>Doriti sa faceti o noua inscriere?</h3>
            <form>
                <Button 
                    mode="back"
                    onClick={()=> history.go(0)}>
                &nbsp;&nbsp;Start&nbsp;&nbsp;</Button> 
            </form>
            <br/>
        </div>
    )               

    const waitMessage = (
        <div className="child-registration-container">
            <br/>
            <img src={warningLogo} style={{margin: 'auto'}} height="80" width="80" alt="check mark" />
            <h1 style={{color: '#fea500'}}>{formState.inputs.name.value} a fost adaugat pe lista de asteptare</h1>
            <p>{message}</p>
            {payment && <p>Cost total: {payment} lei!</p>}
            <h3>Doriti sa faceti o noua inscriere?</h3>
            <form>
                <Button 
                    mode="next"
                    onClick={()=> history.push('/child-registration')}>
                &nbsp;&nbsp;Start&nbsp;&nbsp;</Button> 
            </form>
            <br/>
        </div>
    )               
    
  
    return (
    <React.Fragment>
        <ErrorModal error = {error} onClear = {clearError} />
        <div className="child-registration-page">
            {isLoading && <LoadingSpinner asOverlay/>}
            {/* <h1 style={{"margin-top":'-1.5vh',}}>Preînscriere</h1> */}
            {phase === 0 && phase0}
            {phase === 1 && phase1}
            {phase === 2 && phase2}
            {phase === 23 && interPhase23}
            {phase === 3 && phase3}
            {phase === 4 && phase4}
            {phase === 5 && phase5}
            {phase === 6 && phase6}
            {phase === 7 && phase7}
            {phase === 8 && endMessage}
            {phase === 9 && waitMessage}
            {/* {phase === 3 && boxesRegister} */}
            {/* {phase === 4 && endMessage} */}
        </div>
    </React.Fragment>
    );
}

export default ChildRegistration;