import React, {useContext, useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LoadingSpinner from '../../shared/components/LoadingSpinner/LoadingSpinner';

import Input from '../../shared/components/Input/Input';
import Button from '../../shared/components/Button/Button';
import ErrorModal from '../../shared/components/ErrorModal/ErrorModal';
import Square from '../../shared/components/Square/Square';
import {VALIDATOR_MIN, VALIDATOR_REQUIRE, VALIDATOR_NOT_EQUAL, VALIDATOR_EMAIL} from '../../shared/util/validators'
import {useHttpClient} from '../../shared/hooks/http-hook';
import {useForm} from '../../shared/hooks/form-hook'
import {AuthContext} from '../../shared/util/auth-context';
import {findweekNo} from '../../shared/util/methods';
import './StartChildRegistration.css';

import checkedLogo from '../../Checkmark.png'

const StartChildRegistration =() =>{
    const auth = useContext(AuthContext);
    const {isLoading, error, sendRequest, clearError, raiseError} = useHttpClient();
    const history = useHistory();
    const [limits, setLimits] = useState();
    const [activities, setActivities] = useState();
    const [phase, setPhase] = useState(1);
    const [wasRegistred, setWasRegistred] = useState(false);

    return (
    <React.Fragment>
    <ErrorModal error = {error} onClear = {clearError} />
        {<div className="add-child-page">
            {isLoading && <LoadingSpinner asOverlay/>}
            <div className="child-registration-container">
                <form  style={{width: '80%', margin: 'auto'}}>
                <h1>Bine ați venit la Vara Împreună 2022!</h1>
                <h3>IMPORTANT! Înainte de a începe înscrierea vă invităm să citiți informațiile generale legate de Vara Împreună <a href="https://donboscobc.ro/vara-impreuna-2022-informatii-utile/" style={{color: 'blue'}} target="_blank">pe site-ul fundației.</a></h3>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Acesta este un formular de înscriere și trebuie validat la sediul fundației, altfel înscrierea nu va fi luată în considerare.</h4>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Înscrierea se face in limita locurilor disponibile. Dacă a mai rămas un singur loc disponibil, acesta va fi ocupat de persoana care termină prima de completat formularul.</h4>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Introducerea unor date false poate duce la anularea înscrierii!</h4>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Înscrierea este considerată finalizată abia după apasarea butonului "FINALIZARE" și la primirea mesajului de confirmare!</h4>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Înscrierea permite selectarea unei singure "Veri Impreună". Dacă se dorește înscrierea pentru mai multe perioade, atunci se va completa din nou formularul!</h4>
                <h4 style={{margin: "0px", padding: "0px", textAlign: "left"}}>Toate câmpurile sunt obligatorii! Completați cu <span style={{backgroundColor: 'lightgray'}}>&nbsp; - &nbsp;</span> acolo nu aveti date (de exemplu pentru 'tutore legal' daca nu este cazul sau pentru adresă).</h4>
                <br/>
                    <Button 
                        onClick={()=>history.push("/registration")}
                        mode="white"
                        // link='/child-registration'
                    >Începe înscrierea </Button>
                </form>
                <br/>
            </div>
        </div>}
    </React.Fragment>
    );
}

export default StartChildRegistration;