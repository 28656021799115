import React from 'react';
import {Link} from 'react-router-dom';

import './button.css';

const button = props => 
    !props.link ? (
        <button className ={[
            'button',
            `button--${props.mode}`
        ].join(' ')} 
            onClick={props.onClick}
            disabled={props.disabled}
            type={props.type}
            title={props.buttonTitle}
            style={props.style}
        >
            {props.children}
        </button>
    ): (
        <Link className ={[
            'link-button',
            `button--${props.mode}`
        ].join(' ')} 
           to={props.link}
        >
            {props.children}
        </Link>
    );

export default button;