

exports.findweekNo = (date, weeks) =>{
    // console.log("date: ", date);
    // console.log("weeks: ", weeks);
    // const date = new Date(dateParam);
    const month = date.split('-')[1];
    const day = date.split('-')[0];
    // console.log("day: ", day);
    // console.log("month: ", month);

    for(let index in weeks) {
        const week = weeks[index];
        if(week.first.month == month && week.first.day <= day){
            if(
                (week.last.month == month && week.last.day >= day) || 
                (week.last.month == +month+1 && week.last.day < day) 
            ){
                // console.log("returneaza din primul if: ", +index+1);
                return +index+1;
            }
        }
        else{
            if(week.first.month == month-1 && week.first.day > day){
                if(week.last.month == month && week.last.day >= day){
                    // console.log("returneaza din al doilea if: ", +index+1);
                    return +index+1;
                }
            }
        }
    };
    return -1;
}

exports.reverseDate = (stringDate) =>{
    try {
        let dateArr = stringDate.split('-');
        let revDate;
        if(dateArr.length === 1){
            dateArr = stringDate.split('.');
            if(dateArr.length === 1){
                dateArr = stringDate.split('/');
                if(dateArr.length === 1){
                    return -1;  
                }
                else{
                    revDate =`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}` 
                } 
        
            }
            else{
                revDate =`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}` 
            }
        }
        else{
            revDate =`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}` 
        }
        console.log('REZULTAT FINAL REVERSE: ', revDate);
        return revDate;
    } catch (error) {
        return error;
    }
   
}