import React from 'react';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const ErrorModal = props =>{
    return (
        <Modal
            onCancel = {props.onClear}
            header = "A aparut o problema!"
            show = {!!props.error}
            footer = {<Button onClick={props.onClear} mode="error">Okay</Button>}
            headerClass = "modal__header__error"
            >
            <p>{props.error}</p>
        </Modal>
    )
}

export default ErrorModal;