import {createContext} from 'react';

export const AuthContext = createContext({
    isLoggedIn: false,
    userId: null,
    userType: null,
    token: null,
    noRooms: null,
    noWeeks: null,
    dates: null,//daca e neaparat nevoie
    today: null,
    thisWeek: null,
    weekDay: null,
    login: ()=>{},
    logout: ()=>{}
})