import React from 'react';

import './Square.css';

const Square = props =>{
    let square;
    switch (props.type) {
        case 'object':
        {
            square = (
                <div className={`Square ${props.glow===true && 'Square-glow'} `}
                onClick = {props.onClick}
                >
                <div className="text">
                    <h2>{props.name}</h2>
                    <hr/>
                    {props.children}
                </div>
            </div>
            )
            break;
        }
        case 'selection':
        {
            square = (
                <div className={`Square ${props.selected===true && 'Square-green'} `}
                onClick = {props.onClick}
                >
                <div className="text">
                    <h2>{props.name}</h2>
                    <hr style={{borderColor:"white", border: "1px solid whte"}}/>
                    {props.children}
                </div>
            </div>
            )
            break;
        }
        case 'team':
        {
            square = (
                <div className={props.available >0 ? `Square Team ${props.selected !="" && `team-${props.selected}`} `:"Gray-Square2"}
                onClick = {props.onClick}
                >
                <div className="text">
                    {/* <h2 style={{color: `${props.color}`}} >{props.name}</h2> */}
                    {props.children}
                </div>
            </div>
            )
            break;
        }
        case 'week':
        {
            square = (
                <div 
                    className={props.selected ? `Square ${props.glow===true && 'Square-glow'}`:"Gray-Square"}
                    onClick = {props.onClick}
                >
                <div className="text">
                    <h2>{props.name}</h2>
                    <hr/>
                    {props.children}
                </div>
            </div>
            )
            break;
        }
        case 'small':
        {
            square = (
                <div 
                    className={"small"}
                    onClick = {props.onClick}
                >
                    {props.children}
                </div>
            )
            break;
        }
        case 'receipt':
            {
                square = (
                    <div 
                        className="receipt"
                        // onClick = {props.onClick}
                    >
                    <div className="rText">
                        <h2>{props.name}</h2>
                        <hr/>
                        {props.children}
                    </div>
                </div>
                )
                break;
            }
        default:
            break;
    }

    return (
       <React.Fragment>
           {square}
       </React.Fragment>
    )
}
export default Square;