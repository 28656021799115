import React, {useReducer, useEffect} from 'react';

import {validate} from '../../util/validators';
import './Input.css';

const inputReducer = (state, action) =>{
    switch (action.type) {
        case 'CHANGE':
           return {
               ...state,
               value: action.val,
               isValid: validate(action.val, action.validators)
           };
        case 'TOUCH':
           return {
               ...state,
               isTouched: true
           };
    
        default:
            return state;
    }
}

const Input = props =>{

    const [inputState, dispatch] = useReducer( inputReducer, {
        value: props.initialValue!=null ? props.initialValue : '',
        isTouched: false,
        isValid: props.initialIsValid || false
    });

    const {id, onInput} = props;
    const {value, isValid} = inputState;

    useEffect(()=>{
        onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    const changeHandler = event =>{
        dispatch({
            type: 'CHANGE',
            val: event.target.value,
            validators: props.validators
        });
    };

    const touchHandler = () =>{
        dispatch({
            type: "TOUCH"
        });
    };

    let inputElement;
    if(props.type === 'text' || props.type === 'date' || 
        props.type === 'password' || props.type === 'number'){
        inputElement = (
            <input
                id = {props.id}
                type = {props.type}
                placeholder = {props.placeholder}
                readonly  = {props.readonly}
                min = {props.min}
                max = {props.max}
                onChange = {changeHandler}
                onBlur = {touchHandler} 
                value = {inputState.value}
            />
        )
    }else{
        if(props.type === 'select'){
           inputElement = (
            <>
            <label>{props.placeholder}</label>
            <select
                disabled = {props.disabled}	
                id = {props.id}
                type = 'select'
                placeholder = {props.placeholder}
                onChange = {changeHandler}
                onBlur = {touchHandler} 
                value = {inputState.value}
            >
                <option key="01" value=" "> 
                    {props.pickOptionText? props.pickOptionText : 
                    "Alege o optiune"} </option>
                {props.options.map(option =>(
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            </>
           )
        }else{
            if(props.type==="checkbox"){
                inputElement=(
                <React.Fragment>
                    <label class="chkMarkContainer">One
                        <input type="checkbox"/>
                        <span class="checkmark"></span>
                    </label>
                    {/* <label class="chkMarkContainer">Two
                        <input type="checkbox"/>
                        <span class="checkmark"></span>
                    </label> */}
                </React.Fragment>
                
                )
            }
        }
    }

    return (
        <div className={`form-control ${!inputState.isValid && inputState.isTouched && 'form-control--invalid'}`}>
            <label htmlFor={props.id}>{props.label}</label>
                {inputElement}
            {!inputState.isValid && inputState.isTouched && <p style={{fontSize:"100%"}}>{props.errorText}</p>}
        </div>
    );
}

export default Input;